<template>
  <div class="showWhiteBgForMobile lateralMarginForMobile PaapPanel" style="padding-right: 1.75rem;flex:1;display:flex;flex-direction:column;">
    <div style="flex:1;">
      <div v-if="alertMsg" v-html="alertMsg" class="alert alert-warning" role="alert" style="margin: 0 0 1rem 0;"></div>
      <div style="max-height: 85rem;overflow-y: scroll;padding-right: 1.75rem;">
        <PaapViewer
          :paap="options.data || {}"
        />
      </div>
    </div>
    <div style="padding-top: 1rem;">
      <div class="hr1" style="border-top-color: rgba(60,60,60,0.2);"></div>
      <div style="display: flex;align-items: center;justify-content: space-between;margin-top: 1.2rem;margin-bottom: 1.75rem;">
        <div style="display: flex;align-items: center;">
          <div
            @click="viewPdf()"
            class="Button1"
            style="
              min-width: 12rem;
              padding-top: 0.75rem;
              padding-bottom: 0.75rem;
              background-color: #f44336;
              margin-right: 1rem;
            "
          >
            <div class="icon">
              <i class="far fa-file-pdf" style="font-size: 1.8rem"></i>
            </div>
            <span class="Btn_content">
              Previzualizare PDF
            </span>
          </div>
          <div v-if="isVersion" v-html="versionCreatedAt" style="padding-right: 1rem;"></div>
        </div>
        <span v-if="isVersion">Versiunea {{versionLabel}}</span>
      </div>
    </div>
  </div>
</template>

<style>
.PaapPanel .panel-results {
  padding-top: 2px !important;
}
</style>

<script>
import PaapViewer from '@/components/PaapViewer'

export default {
  props: {
    options: {
      type: Object,
      default: ({})
    }
  },
  components: {
    PaapViewer
  },
  data() {
    return {
      alertMsg: ''
    }
  },
  computed: {
    isVersion() {
      return this.options.action == 'viewVersion'
    },
    versionStatus() {
      const x = this.options.data?.paapStatus
      return x ? (({
        'asteptare_aprobare': 'Așteptare aprobare',
        'rejected': 'Respins',
        'approved': 'Aprobat'
      }[x]) || x) : ''
    },
    versionLabel() {
      return this.options.data?.label ?? ''
    },
    versionCreatedAt() {
      const x = this.options.data?.versionCreatedAt
      return  x ? this.toDateAndTime(x) : '<b style="color: #ec4a4a;">Data creării nu a fost găsită.</b>'
    }
  },
  methods: {
    closePanel() {
      this.$emit('closePanel')
    },
    viewPdf() {
      const id = this.options?.data?.id;
      if(!id) {
        this.$toastr.e('PAAP Id not found.');
        return
      }

      this.$emit('viewPdf', {id});
    },
  },
  created() {
    if(this.isVersion) {
      const x = this.options.data
      const cause = x?.updateCause ?? null

      if(cause) {
        const msgMap = {
          'NeedReferate': details => `Această versiune a apărut ca urmare a editării referatului <a href="/referate/${details?.id ?? '/'}/${details?.versionNumber + 1}"><b>${details?.name || '-'}</b></a>.`,
          'Need': details => `Această versiune a apărut ca urmare a editării propunerii de nevoi cu produsul <b>${details?.name || '-'}</b>.`
        }

        this.alertMsg = msgMap[cause] ? msgMap[cause](x.details) : ''
      }
    }
  }
}
</script>