<template>
  <div class="ModalParentModal">
    <div class="Popup_Inner_main">
      <div @click="close" class="Popup_close_btn">
        <img
          @load="imgLoaded"
          class="imgHide"
          src="@/assets/media/vectors/close.svg"
          alt=""
        />
      </div>
      <div style="width: 100%;">
        <div class="Popup_title" style="margin-top: 0.5rem;">
          Versiuni precedente
        </div>
        <div class="Popup_subTitle">
          {{ paapName }}
        </div>
        <div>
          <div class="form-group">
            <b>Alege versiunea</b>
            <v-select
              v-model="selectedVersion"
              :options="preparedVersions"
              :loading="isDropdownLoading"
              :searchable="false"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      class="Popup_footer"
      style="display: flex;justify-content: space-between;"
    >
      <div class="Popup_footer_col">
        <button @click="close" class="Popup_footer_btn Footer_btn1">
          Inapoi
        </button>
      </div>
      <div class="Popup_footer_col">
        <transition name="advencedFade" :duration="{ enter: 500, leave: 200 }">
          <button v-show="canOpen" @click="openVersion()" class="Popup_footer_btn Footer_btn2">
            Deschide
          </button>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { PAAP } from '@/api.js'

export default {
  props: {
    options: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      versionsData: {},
      selectedVersion: null,
      isDropdownLoading: false
    }
  },
  computed: {
    paapName() {
      return this.options.name
    },
    canOpen() {
      return !! this.selectedVersion
    },
    preparedVersions() {
      const versionsQuantity = this.versionsData.versionsQuantity ?? 0

      return this.versionsData.result?.map(e => {
        const num = parseFloat(e.versionNumber) + 1

        let label = `V${num?.toFixed(2)} - ${new Date(e.createdAt).toLocaleString("ro-ro", { day:"numeric", month: "numeric", year: "numeric" })}`

        if(num >= versionsQuantity) {
          label = `V${num?.toFixed(2)}` + ' — Curentă - ' + new Date(e.createdAt).toLocaleString("ro-ro", { day:"numeric", month: "numeric", year: "numeric" })
        }

        return {
          ...e,
          label,
          id: e.versionNumber
        }
      })
    }
  },
  methods: {
    close() {
      this.$emit("Close")
    },
    syncVersions() {
      const paapId = this.options.id

      if(!Number.isInteger(paapId)) return

      const error = msg => {
        this.$toastr.e(msg || 'Încărcarea versiunilor a eșuat.')
        this.close()
      }

      this.isDropdownLoading = true
      PAAP.getVersions(paapId).then(res => {
        if(Array.isArray(res?.data?.result)) {
          this.versionsData = res.data

          this.isDropdownLoading = false
        } else {
          error()
        }
      }).catch(error)
    },
    openVersion() {
      const version = this.selectedVersion

      if(!this.isObject(version)) return

      this.$emit('openVersion', this.options.id, version.id, this.versionsData.result, this.versionsData.versionsQuantity)
    }
  },
  created() {
    this.syncVersions()
  },
}
</script>
